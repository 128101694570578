import './App.css';
import {useEffect} from "react";
import ReactGA from "react-ga";
import MainPage from "./pages/MainPage";
import MapProductPage from "./pages/MapProductPage";
import WhatsAppLink from './components/WhatsAppLink'
import {
  BrowserRouter as Router, Route, Routes,
} from 'react-router-dom';
import Navbar from "./components/Navbar";

function App() {

  useEffect(() => {
    ReactGA.initialize("G-TZC5G8SHYH");
    ReactGA.pageview(window.location.pathname);
  }, []);

  return (
    <Router>
      <Navbar />
      <WhatsAppLink />
      <Routes>
        <Route exact path="/" element={<MainPage />} />
        <Route path="/regulations" element={<MainPage />} />
        <Route path="/producta" element={<MapProductPage productIndex={'A'} />} />
        <Route path="/productb" element={<MapProductPage productIndex={'B'} />} />
      </Routes>
    </Router>
  );
}

export default App;
