import React from 'react';

const WideImageSlice = ({ imageUrl, part }) => {
  const getBackgroundPosition = () => {
    switch (part) {
      case 'top':
        return 'top';
      case 'middle':
        return 'center';
      case 'bottom':
        return 'bottom';
      default:
        return 'top';
    }
  };

  return (
    <div
      className="w-full h-72 bg-cover bg-no-repeat"
      style={{
        backgroundImage: `url(${imageUrl})`,
        backgroundPosition: getBackgroundPosition(),
        backgroundSize: '100% auto',
      }}
    ></div>
  );
};

export default WideImageSlice;
