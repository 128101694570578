import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import HamburgerIcon from '../HumburgerIcon';
import { menuItems } from './constants';
import tree87logo from '../../assets/tree87logo.svg';
import natalia from '../../assets/natalia.svg';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const handleSectionClick = (to) => {
    const [route, section] = to.split('#');
    navigate(route);

    setTimeout(() => {
      document.getElementById(section)?.scrollIntoView({ behavior: 'smooth' });
    }, 100);
    setIsOpen(false); // Close the menu on navigation
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };


  return (
    <nav className="p-4 fixed top-0 left-0 w-full z-50 bg-tree87Green">
      <div className="flex items-center justify-between flex-wrap">
        <div className="block sm:hidden">
          <button onClick={toggleMenu} className="text-white focus:outline-none">
            <HamburgerIcon isOpen={isOpen} />
          </button>
        </div>

        <div className={`${isOpen ? 'block' : 'hidden'} w-full sm:flex sm:justify-center`}>
          {
            !isOpen && <Link to="/">
              <img src={tree87logo} alt="Logo" className="h-12 w-12 mr-2"/>
            </Link>
          }
          <div className="text-20 sm:flex-grow sm:flex sm:justify-center">
            {
              menuItems.map((item, index) => {
                if (item.type === 'link') {
                  return (
                    <Link to={item.to}
                          key={item.type + index}
                          className="block mt-4 sm:mt-0 text-white  mr-4 self-center">
                      {item.label}
                    </Link>
                  )
                } else if (item.type === 'section') {
                  return (
                    <button
                      key={item.type + index}
                      onClick={() => handleSectionClick(item.to)}
                      className="block mt-4 sm:mt-0 text-white  mr-4"
                    >
                      {item.label}
                    </button>
                  )
                }
                return null;
              }).filter(x => !!x)
            }
          </div>
          {
            !isOpen && <img src={natalia} alt="alternative logo" className="h-12 w-12 mr-2"/>
          }
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
