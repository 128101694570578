import tree87greenLogo from "../assets/images/tree87-logo-384.png";
import React from "react";

export default function RoundBorderWithText({ radius, title, messages }) {
  const borderRadiusStyle = {
    borderTopLeftRadius: `${radius}px`,
    borderTopRightRadius: `${radius}px`,
  };
  return (
    <div className="border border-gray-300 p-2 h-1/2 flex flex-col m-3 align-center justify-center
    text-center text-black"
         style={borderRadiusStyle}>
      <img src={tree87greenLogo} alt="tree87 logo" className=" mx-auto mb-16 m-0 color-tree87Green"/>
      <h2 className="text-xl font-bold mb-2 font-lia-shamaim">{title}</h2>
      <ul>
        {
          messages.map(message => (
            <li className="font-polin-light text-center" key={message}>{message}</li>
          ))
        }
      </ul>
    </div>
  )
}
