import React from 'react';
import {WORDS} from "../../dictionary/words";
import tree87opaqueSymbol from "../../assets/images/tree87opaqueSymbol.png";
import product1_small from "../../assets/images/product1_small.png";
import product2_small from "../../assets/images/product2_small.png";
import ProductCard from "../../components/Product/Product";

export default function Products() {

  return (
    <section id="products" className="relative min-h-screen">
      <div className="relative w-full h-full max-w-4xl mx-auto p-6">
          <div className="text-center text-black mt-20">
            <p className="text-2xl md:text-4xl font-lia-shamaim">{WORDS.PRODUCTS.TITLE}</p>
          </div>
          <div className="flex items-center justify-between p-1 min-h-screen flex-wrap">
            <ProductCard caption={WORDS.PRODUCTS.PRODUCT_A.BUTTON_CAPTION}
                         icon={tree87opaqueSymbol}
                         imageUrl={product1_small}
                         productName={WORDS.PRODUCTS.PRODUCT_A.NAME}
                         link={WORDS.PRODUCTS.PRODUCT_A.LINK_TO_PAGE}
            />
            <ProductCard caption={WORDS.PRODUCTS.PRODUCT_B.BUTTON_CAPTION}
                         icon={tree87opaqueSymbol}
                         imageUrl={product2_small}
                         productName={WORDS.PRODUCTS.PRODUCT_B.NAME}
                         link={WORDS.PRODUCTS.PRODUCT_B.LINK_TO_PAGE}
            />
          </div>
        </div>
    </section>
);
}
