export function buildDateArray(givenDate) {
  const date = new Date(givenDate);
  const currentYear = new Date().getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Get month as mm (01-12)

  const result = [];

  for (let i = 0; i < 3; i++) {
    const year1 = currentYear + i;
    const year2 = year1 + 1;

    result.push({
      value: `${year1}`,
      label: `${month}/${year1} - ${month}/${year2}`
    });
  }

  return result;
}
