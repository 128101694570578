const WEBSITE_REGULATIONS_URL = 'https://tree87-pdf-reports.s3.eu-north-1.amazonaws.com/web_regulation.pdf';
const LIST_OF_FIELDS = [
    {
      PLACEHOLDER: 'שם פרטי בעברית',
      ID: 'firstName',
      ROW: 10,
    },
    {
      PLACEHOLDER: 'שם משפחה בעברית',
      ID: 'lastName',
      ROW: 20,
    },
    {
      PLACEHOLDER: 'ת. לידה',
      ID: 'date_of_birth',
      ROW: 30,
    },
    {
      PLACEHOLDER: 'מין',
      ID: 'gender',
      ROW: 40,
      OPTIONS: [
        {
          value: 'male',
          label: 'זכר',
        },
        {
          value: 'female',
          label: 'נקבה',
        },
      ],
    },
    {
      PLACEHOLDER: 'טלפון',
      ID: 'phoneNumber',
      ROW: 50,
    },
    {
      PLACEHOLDER: 'אימייל',
      ID: 'email',
      ROW: 60,
    },
  {
    ID: 'termsAndConditions',
    PLACEHOLDER: 'לביצוע רכישה יש לאשר את תקנון האתר',
    URL: WEBSITE_REGULATIONS_URL,
    ROW: 1000,
  }
];
export const WORDS = {
  NATALIA_FULL_NAME: 'נטליה קושניריוב',
  KABALISTIC_NUMEROLOGIST: 'נומרולוגית קבלית מוסמכת',
  ABOUT: {
    NICE_TO_MEET_YOU: 'נעים להכיר',
    MY_NAME_IS_TITLE: 'שמי נטליה קושניריוב',
    MY_NAME_IS: 'ואני נומרולוגית קבלית מוסמכת ומנוסה. לאורך השנים צברתי ידע מעמיק וניסיון רב בייעוץ והכוונה באמצעות תורת הנומרולוגיה הקבלית, אותה אני רואה כמורה דרך חשוב לחיים.',
    MY_DESIGNATION_TITLE: 'הייעוד שלי',
    MY_DESIGNATION: 'הוא להנחות ולהוביל אנשים לגלות ולממש את הפוטנציאל הגלום בתאריך לידתם ובשמם. לאחר הפגישה איתי, אנשים יוצאים עם תובנות וכלים חדשים שלא היו ברשותם קודם לכן, מביטים על המציאות שסביבם ועל עצמם במבט רענן, ויודעים כיצד להתמודד עם האתגרים העומדים בפניהם. כתוצאה מכך, הם חשים שלווה וביטחון בנוגע לסיבת הפנייה אליי. עבורי, זו ההגשמה האמיתית. כיועצת, אני זוכה לראות אנשים משתנים לנגד עיניי ומתפתחים במהלך התהליך. עבורי, זו זכות גדולה ללוות אותם, להיות חלק משינויים משמעותיים בחייהם, ולצמוח יחד איתם.',
    NUMEROLOGY_THEORY_TITLE: 'הנומרולוגיה מאפשרת',
    NUMEROLOGY_THEORY: 'לנו לזהות את הייעוד שלנו בכל תחומי החיים. להבין את עצמנו ברמה עמוקה יותר, לגלות את תכונות האופי הייחודיות שלנו, ולזהות מה עלינו לחזק. בעזרת ניתוח מספרים ותאריכים, ניתן לחשוף את הכוחות המניעים אותנו ואת האתגרים שאנו ניצבים מולם.',
    HELPS_WITH_TITLE: 'בייעוץ הנומרולוגי',
    HELPS_WITH: 'שלי אני מנחה את לקוחותיי בהבנת עיתויים בהם הם נמצאים ובדרכים לפעול בהן בחוכמה. אני מסייעת למצוא פתרונות להתלבטויות ולבצע החלטות נכונות ברגעים משמעותיים, לבחור את הנתיב המקצועי האופטימלי עבורם וליצור איזון והרמוניה בחייהם ובזוגיותם. אני מכוונת את הבחירה של המועד המתאים עבור פתיחת עסק חדש, מעבר דירה, שינוי קריירה, ועוזרת בקביעת שם התינוק שיהיה מחובר לתאריך לידתו.',
  },
  NUMEROLOGY_1: {
    TITLE: 'המשפט של תורת נומרולוגיה הוא',
    SUBTITLE: 'הכל צפוי והרשות נתונה',
    RIGHT: {
      TITLE: 'הכל צפוי',
      TEXT: 'לכל אדם יש ייעוד ייחודי בחיים שאיתו הוא נולד, משהו שהוא צריך לעשות או להשיג. ' +
        'לאורך החיים, נקבל הזדמנויות רבות שיכולות לעזור לנו למצוא ולקיים את הייעוד שלנו. ' +
        'זה יכול להגיע בצורות שונות, כמו עבודה, מערכות יחסים, חוויות ועוד. ' +
        'מידי פעם, החיים ״יבדקו״ אותנו. זה יכול להיות קשה, אבל חשוב לזכור שזה לא סתם. מטרת ״בדיקות״ האלה היא לעזור לנו לצמוח ולהתפתח, ' +
        'ולהבטיח שאנחנו בדרך הנכונה למימוש הייעוד שלנו.',
    },
    LEFT: {
      TITLE: 'והרשות נתונה',
      TEXT: 'החיים מלאים בהתלבטויות ובבחירות, החל מהקטנות והיומיומיות ועד לגדולות והגורליות. ' +
        'כל בחירה משפיעה על עתידנו ועל האופן שבו אנו חווים את העולם. כל בחירה קטנה משפיעה על מסלול חיי אדם ומקרבת אותו או מרחיקה אותו מהגשמת ייעודו. ' +
        'הגורל, כמדריך נסתר, מלווה אותו לאורך הדרך, מעניק לו תגמולים כאשר הוא בוחר נכונה ומציב לו אתגרים כאשר הוא סוטה מהדרך המיועדת לו. כך, לאורך חייו,' +
        ' הוא מקבל תשובות מוחשיות על הבחירות שעשה בתחומים שונים, כמו זוגיות, מקום מגורים, קריירה, פרנסה ועוד.',
    },
  },
  NUMEROLOGY_2: {
    TITLE: 'איך נומרולוגיה יכולה להיות לעזר?',
    MESSAGES: [
      {
        TITLE: 'הבנה עצמית',
        MESSAGES: [
          'גילוי חוזקות וחולשות אישיות',
          'הבנת דפוסי התנהגות',
          'זיהוי כישרונות ויכולות',
          'קבלת תובנות לגבי ייעוד ומסע חיים',
        ],
      },
      {
        TITLE: 'מערכות יחסים',
        MESSAGES: [
          'שיפור דינמיקות זוגיות',
          'הבנת תאימות בין בני זוג',
          'התמודדות עם אתגרים בזוגיות',
          'קבלת הדרכה לגבי בחירת בן/בת זוג',
        ],
      },
      {
        TITLE: 'קריירה',
        MESSAGES: [
          'קבלת החלטות מקצועיות',
          'מציאת תחום קריירה מתאים',
          'התקדמות מקצועית',
          'התמודדות עם קשיים בעבודה',
        ],
      },
      {
        TITLE: 'אתגרים אישיים',
        MESSAGES: [
          'התמודדות עם קשיים רגשיים',
          'קבלת החלטות חשובות',
          'התמודדות עם שינויים בחיים',
          'הבנת משמעות אירועים בחיים',
        ],
      },

    ],
  },
  NUMEROLOGY_3: {
    TITLE: 'נושאים ושאלות איתן אפשר לפנות',
    MESSAGES: [
      {
        header: 'שאלות בנושא קריירה',
        id: 1,
        items: [
          ' מהו תחום הקריירה המתאים ביותר עבורי בהתבסס על כישוריי, נטיותיי ותכונותיי האישיות?',
          '  אילו אתגרים צפויים לי בדרכי המקצועית, וכיצד אוכל להתמודד איתם?',
          ' מהן ההזדמנויות הטובות ביותר עבורי מבחינה מקצועית בשנים הקרובות?',
          'האם ישנם תחומים חדשים שכדאי לי לשקול ללמוד או להתפתח בהם?',
          'מהן הדרכים הטובות ביותר לקדם את הקריירה שלי ולהגיע להצלחה?',
        ],
      },
      {
        header: 'שאלות בנושא זוגיות',
        id: 2,
        items: [
          'אילו תכונות חשובות לי בבן/בת זוג, בהתאם למפת הנומרולוגיה האישית שלי?',
          'אילו אתגרים עשויים להופיע במערכות יחסים על פי ניתוח נומרולוגי מעמיק?',
          'באילו אמצעים ניתן לשפר את התקשורת במערכת היחסים הנוכחית שלי?',
          'התאמה זוגית, חוזקות וחולשות.',
        ],
      },
      {
        header: 'שאלות בנושא שמות לילדים',
        id: 3,
        items: [
          'אילו שמות מתאימים לילדי בהתבסס על תאריך הלידה שלו/שלה ועל מפת הנומרולוגיה האישית?',
          'אילו משמעויות נושאות כל אחת מהאפשרויות, ואיזו מהן עשויה לתרום לחוזקות ולתכונות חיוביות אצל ילדי?',
          'האם ישנם שמות שעלולים ליצור קשיים או אתגרים עבור ילדי?',
          'באיזה שם כדאי לבחור, בהתבסס על ההתאמה ביני לבין בן/בת הזוג?',
        ],
      },
      {
        header: 'שאלות שונות',
        id: 4,
        items: [
          'מהו הזמן המיטבי לעבור דירה על פי הנומרולוגיה?',
          'האם זהו העיתוי הנכון להחלפת מקום עבודה?',
          'מהו הייעוד שלי לשנה זו לפי הנומרולוגיה?',
          'מתי כדאי לי להתחיל משהו חדש על פי המספרים שלי?',
          'מהו המסר הנומרולוגי של השנה עבורי?',
        ],
      },
    ],
  },
  NUMEROLOGY_4: {
    TITLE: '',
    SUBTITLE: 'נומרולוגיה היא',
    RIGHT: {
      TITLE: 'לא כלי ניבוי',
      TEXT: 'אומנם נומרולוגיה יכולה לשער מה מצפה לאדם לפי העיתויים השונים בחייו, אך לא בכך טמון כוחה האמיתי. נומרולוגיה לא יכולה להכתיב גורל או לחזות אירועים ספציפיים, ולא מדובר במפת דרכים נוקשה המכתיבה לאדם את מהלכי חייו. נומרולוגיה היא גם לא פתרון קסם לבעיות, ולא תחליף לבחירה אישית ולהחלטות מודעות.',
    },
    LEFT: {
      TITLE: 'כלי ייעוץ',
      TEXT: 'כוחה של הנומרולוגיה טמון ביכולתה להציע הכוונה ותובנות, המאפשרות לאדם להבין את עצמו טוב יותר ולפעול בהתאם לעיתויים המתאימים בחייו. בסופו של דבר, הנומרולוגיה היא כלי לייעוץ והתפתחות אישית, המכוון את האדם לבחירות נכונות ולצמיחה מודעת.\n' +
        'היא מציעה נקודת מבט נוספת, תובנות על מחזורי החיים והכוחות הפועלים ברקע, ומסייעת לאדם להבין את עצמו ואת השפעות התקופות השונות. בכך, היא מעודדת חשיבה מעמיקה והתבוננות פנימית, ומכוונת לאיזון ולהתפתחות אישית.',
    },
  },
  CONTACT_US: {
    TITLE: 'צרו איתי קשר לכול שאלה',
    FORM: {
      SUBMIT: {
        LABEL: 'שליחה',
        ID: 'contact_us_submit',
      },
      FULL_NAME_MESSAGE: (first, last) => {
        const startOfMessage = first || last ? `הי, שמי ${first || ''} ${last || ''}, ` : 'הי';
        return `${startOfMessage} אני רוצה לקבל מידע לגבי ייעוץ נומרולוגי`;
      },
      LIST_OF_FIELDS: [
        {
          PLACEHOLDER: 'שם פרטי בעברית',
          ID: 'first_name',
        },
        {
          PLACEHOLDER: 'שם משפחה בעברית',
          ID: 'last_name',
        },
      ],
    },
    DATA: {
      RIGHT: {
        TITLE: 'כתובת וטלפון',
        NAME: 'נטליה קושניריוב',
        STREET: 'השיקמים 6',
        ADDRESS: 'אור עקיבא, ישראל 3061859',
        PHONE: '054-5421333',
        ACCESSIBILITY: 'הצהרת נגישות',
        DESIGNED_BY: 'עוצב על ידי',
      },
      LEFT: {
        PRIVACY_POLICY: 'מדיניות פרטיות',
        WEBSITE_REGULATIONS: 'תקנון האתר',
        WEBSITE_REGULATIONS_URL,
      },
    },
  },
  PRODUCT_FORM: {
    LIST_OF_FIELDS: [ ...LIST_OF_FIELDS],
    SUBMIT_CAPTION: 'לשלוח בקשה',
  },
  PRODUCTS: {
    TITLE: 'מוצרים נומרולוגיים',
    PRODUCT_A: {
      OVERLAY_SUCCESS_MESSAGE: 'בקשה נשלחה בהצלחה. תחזית נומרולוגית תשלח בקרוב.',
      PRODUCT_ID: 'PRODUCT_A',
      BUTTON_CAPTION: 'פרוט על מוצר',
      NAME: 'מפה נומרולוגית שנתית',
      LINK_TO_PAGE: 'producta',
      DESCRIPTION: 'התחזית הנומרולוגית שנתית תספק:',
      ITEMS: [
        'תחזית אישית חודשית מבוססת על נומרולוגיה',
        'תובנות על הזדמנויות ואתגרים בכל תחומי חיים',
        'הכוונה מעשית כיצד לפעול ולהתקדם בשנה הקרובה',
        'כלים והמלצות לשיפור איכות החיים והצלחה',
      ],
      PRODUCT_FORM: {
        LIST_OF_FIELDS: [
          ...LIST_OF_FIELDS,
          {
            PLACEHOLDER: 'עבור תקופה',
            ID: 'years_period_first_year',
            ROW: 35,
            disabledWithoutID: 'date_of_birth',
            OPTIONS: [

            ],
          },
        ],
        SUBMIT_CAPTION: 'לשלוח בקשה',
      },
    },
    PRODUCT_B: {
      OVERLAY_SUCCESS_MESSAGE: 'בקשה נשלחה בהצלחה. תחזית נומרולוגית תשלח בקרוב.',
      PRODUCT_ID: 'PRODUCT_B',
      BUTTON_CAPTION: 'פרוט על מוצר',
      NAME: 'מפה נומרולוגית לפי חודשים',
      LINK_TO_PAGE: 'productb',
      DESCRIPTION: 'התחזית הנומרולוגית שנתית לפי חודשים תספק:',
      ITEMS: [
        'תחזית אישית חודשית מבוססת על נומרולוגיה',
        'תובנות על הזדמנויות ואתגרים בכל תחומי חיים',
        'הכוונה מעשית כיצד לפעול ולהתקדם בשנה הקרובה',
        'כלים והמלצות לשיפור איכות החיים והצלחה',
        'תאור מדויק של כל אלו עבור כל חודש בנפרד',
      ],
      PRODUCT_FORM: {
        LIST_OF_FIELDS: [ ...LIST_OF_FIELDS],
        SUBMIT_CAPTION: 'לשלוח בקשה',
      },
    },
  },
}


// בייעוץ הנומרולוגי שלי אני מנחה את לקוחותיי בהבנת עיתויים בהם הם נמצאים ובדרכים לפעול בהן בחוכמה. אני מסייעת להם למצוא פתרונות להתלבטויות ולבצע החלטות נכונות ברגעים משמעותיים, לבחור את הנתיב המקצועי האופטימלי עבורם וליצור איזון והרמוניה בחייהם ובזוגיותם. אני מכוונת את הבחירה של המועד המתאים עבור פתיחת עסק חדש, מעבר דירה, שינוי קריירה, ועוזרת בקביעת שם התינוק שיהיה מחובר לתאריך לידתו.

