import About from "../About";
import Header from '../Header'

import React from 'react';
import {Numerology1, Numerology2, Numerology3, Numerology4} from "../Numerology";
import ContactUs from "../ContactUs";
import Products from "../Products";

const MainPage = () => {
  return (
    <div className="relative min-h-screen" dir="rtl">
      <main className="relative z-10 text-white">
        <Header/>
        <About/>
        <Numerology1/>
        <Numerology2/>
        <Numerology4/>
        <Numerology3/>
        <Products />
        <ContactUs/>
      </main>
    </div>
  );
};

export default MainPage;
