import {useRef, useState} from "react";

const DateInput = ({ label, name, onDateChange }) => {
  const [selectedDate, setSelectedDate] = useState("");
  const dateInputRef = useRef(null);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSelectedDate(value)
    onDateChange({ target: { name, value }});
  };

  const handleClick = () => {
    if (dateInputRef.current) {
      dateInputRef.current.focus();  // Triggers the date picker by focusing the input
    }
  };

  return (
    <div className="relative w-full">
      <input
        type="date"
        value={selectedDate}
        onChange={handleInputChange}
        onClick={handleClick}
        placeholder={label}
        className="
          h-12
          text-center
          appearance-none
          focus:border-tree87Green
          focus:outline-none
          border-tree87Green-300
          w-full
          px-4
          py-2
          border
          rounded-full
          text-gray-900
          bg-white
          {/*min-w-0*/}
          text-opacity-50
        "
      />
      <label
        className={`
          absolute right-4 md:right-12 top-2 transition-all duration-300 text-gray-400 pointer-events-none         
        `}
      >
        {label}
      </label>
    </div>
  );
};

export default DateInput;
