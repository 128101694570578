export const MENU_ITEMS = {
  HOME_PAGE: 'דף הבית',
  ABOUT: 'אודות',
  NUMEROLOGY: 'נומרולוגיה',
  PRODUCTS: 'מוצרים',
  CONTACT_US: 'צור קשר',
}

export const MESSAGES = {
  WHATS_APP_MESSAGE_TEXT: "שלום, אני מעוניינת לקבוע שיחת יעוץ עם נומרולוגית קבלית",
  WHATS_APP_URL: 'https://wa.me/972545421333?text=',
}
