import { useState } from "react";

const PhoneNumberInput = ({ label, name, onPhoneNumberChange }) => {
  const [phoneNumber, setPhoneNumber] = useState("");

  const handleInputChange = (e) => {
    const value = e.target.value;

    // Regular expression to allow only numbers and specific symbols for phone numbers
    const phoneRegex = /^[0-9+()-\s]*$/;

    if (phoneRegex.test(value)) {
      setPhoneNumber(value);
      onPhoneNumberChange({ target: { name, value }});  // Send the updated phone number to the parent
    }
  };

  return (
    <div className="w-full">
      <input
        type="tel"
        value={phoneNumber}
        onChange={handleInputChange}
        placeholder={label}
        className="w-full text-right px-4 py-2 border border-tree87Green-300 rounded-full shadow-sm focus:outline-none focus:ring-2 focus:ring-tree87Green-500 focus:border-tree87Green-500"
      />
    </div>
  );
};

export default PhoneNumberInput;
