import React from 'react';
import tree87greenLogo from "../../assets/images/tree87-logo-384.png";
import {Link, useNavigate} from "react-router-dom";

const ProductCard = ({ caption, link, imageUrl, productName }) => {
  const navigate = useNavigate();

  return (
    <div className="hover:cursor-pointer border-2 border-black rounded-t-full rounded-b-none w-80 text-center p-2 m-4"
         onClick={() => navigate(link)}>
      <div className="rounded-t-full overflow-hidden">
        <img src={imageUrl} alt={productName} className="w-full"/>
      </div>
      <div className="py-5">
        <img src={tree87greenLogo} alt="tree87 logo" className="mx-auto mb-4 m-0 color-tree87Green"/>
        <h3 className="text-2xl text-black font-semibold mb-3">{productName}</h3>
        <Link to={link}>
          <button
            className="border-2 border-tree87Green text-black bg-transparent py-2 px-6 rounded-full
            transition-colors duration-300 hover:bg-tree87Green hover:text-white">
            {caption}
          </button>
        </Link>
      </div>
    </div>
  );
};

export default ProductCard;
