import React from 'react';

const CustomInput = ({ type, name, value, placeholder, onChange, onFocus, onInput, className, ...props }) => {

  const handleChange = (e) => {
    const value = e.target.value;
    const shouldSkipHebrewValidation = name === 'email';
    const hebrewRegex = /^[\u0590-\u05FF\s]*$/;

    if (value === "" || shouldSkipHebrewValidation || hebrewRegex.test(value)) {
      onChange(e);
    } else {
      console.warn('no change made, not valid');
    }
  }

  return (
    <input
      type={type}
      name={name}
      value={value}
      placeholder={placeholder}
      onChange={handleChange}
      onFocus={onFocus}
      onInput={onInput}
      className={`
        focus:border-tree87Green 
        focus:ring-tree87Green 
        focus:ring-2
        focus:outline-none
        border-tree87Green-300  
        text-right border
        border-gray-300 
        py-2 
        px-4 
        rounded-full             
        ${className}`
      }
      {...props}
    />
  );
};

export default CustomInput;
