import { useState, useRef, useEffect } from "react";

const Dropdown = ({ items, label, name, onChange, disabled }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleSelectItem = (item) => {
    setSelectedItem(item);
    onChange({target: { value: item.value, name }});
    setIsOpen(false);
  };

  // Close dropdown if clicked outside
  const handleClickOutside = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="w-full relative inline-block" ref={dropdownRef}>
      <div className="w-full relative">
        <button
          disabled={disabled}
          onClick={toggleDropdown}
          className="w-full px-4 py-2 bg-white border  rounded-full shadow-sm
          text-gray-400 text-right
          focus:outline-none focus:ring-2 focus:ring-tree87Green-500 focus:border-tree87Green-500"
        >
          {selectedItem ? selectedItem.label : label}
          <span className="float-right text-gray-500">
            {isOpen ? "▲" : "▼"}
          </span>
        </button>

        {isOpen && (
          <ul className="absolute z-10 mt-1 w-full bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-auto">
            {items.map((item) => (
              <li
                key={item.value}
                onClick={() => handleSelectItem(item)}
                className="cursor-pointer px-4 py-2 hover:bg-tree87Green hover:text-white transition-colors"
              >
                {item.label}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default Dropdown;
